import React, { useState, useEffect } from 'react';
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/pages/container/container"
import ContainerFluid from "../components/pages/container/container-fluid"

import "../scss/homepagestyle.scss"

import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"

type DataProps = {
  data: IndexQueryQuery
  location: Location
}

const HomeOldPage: React.FC<PageProps<DataProps>> = ({ location }) => {

  const data = useStaticQuery(
    graphql`
      query {
        hero_background: file(relativePath: { eq: "about-us-hero-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        hero_img: file(relativePath: { eq: "hero-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        section2_background: file(relativePath: { eq: "section2-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        why_left_img: file(relativePath: { eq: "left-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon_1: file(relativePath: { eq: "icon1.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon_2: file(relativePath: { eq: "icon2.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        sectors_background: file(relativePath: { eq: "sectors-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon_3: file(relativePath: { eq: "icon3.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        icon_4: file(relativePath: { eq: "icon4.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        sectors_img: file(relativePath: { eq: "sectors-right-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        co_peers_background: file(relativePath: { eq: "section5-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        co_peers_img: file(relativePath: { eq: "co-peers-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        how_we_works_img: file(relativePath: { eq: "how-we-work-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        information_background: file(relativePath: { eq: "section7-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        investment_background: file(relativePath: { eq: "investment-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        register_img: file(relativePath: { eq: "register-img.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

//declare image Data
const hero_background = getImage(data.hero_background)
const hero_img = getImage(data.hero_img)
const section2_background = getImage(data.section2_background)
const why_left_img = getImage(data.why_left_img)
const icon_1 = getImage(data.icon_1)
const icon_2 = getImage(data.icon_2)
const icon_3 = getImage(data.icon_3)
const icon_4 = getImage(data.icon_4)
const sectors_background = getImage(data.sectors_background)
const sectors_img = getImage(data.sectors_img)
const co_peers_background = getImage(data.co_peers_background)
const co_peers_img = getImage(data.co_peers_img)
const how_we_works_img = getImage(data.how_we_works_img)
const information_background = getImage(data.information_background)
const investment_background = getImage(data.investment_background)
const register_img = getImage(data.register_img)

//for bg image
const hero_bg = convertToBgImage(hero_background)
const section2_bg = convertToBgImage(section2_background)
const sectors_bg = convertToBgImage(sectors_background)
const co_peers_bg = convertToBgImage(co_peers_background)
const information_bg = convertToBgImage(information_background)
const investment_bg = convertToBgImage(investment_background)

  return (
    <>
    <Layout  >
      <Seo
        title="About Us"
        description="about us"
      />

        <BackgroundImage
          Tag="section"
          className="about-us-bg"
          {...hero_bg}
          preserveStackingContext
        >
          <section id="about-us-hero">
            <Container>
              <div className="col-lg-7">
                <h3>ABOUT US</h3>
                <h1>PEER CLUB</h1>
                <p>The Peer Club is a members only institution where peers from every country, industry, profession, sector and discipline come together to help each other solve problems and meet challenges.</p>
              </div>
              <div className="col-lg-5">
                <GatsbyImage
                  image={hero_img}
                  alt="Group of people"
                  className="about-us-hero-img"
                />
              </div>
            </Container>
          </section>
        </BackgroundImage>

        <BackgroundImage
          Tag="section"
          className="cta-bg"
          {...section2_bg}
          preserveStackingContext
        >
          <section id="cta">
            <Container>
              <div className="col-lg-12">
                <p className="text-center cta-text text-white">
                  They do this by generously sharing expertise and knowledge in the common cause of helping each other.
                </p>
                <hr />
                <p className="text-center text-white">
                  Our peers are never charged for their meetings and use of the Club’s impressive resources.
                </p>
                <Link to="#">
                  <button className="primary-btn">JOIN NOW</button>
                </Link>
              </div>
            </Container>
          </section>
        </BackgroundImage>

        <section id="why-peer-club">
          <Container>
            <div className="col-lg-6">
              <GatsbyImage
                image={why_left_img}
                alt="People"
                className="why-img"
              />
              <Link to="#">
                <button className="primary-btn">JOIN NOW</button>
              </Link>
            </div>
            <div className="col-lg-6">
              <h3>WHY</h3>
              <h2>PEER CLUB</h2>
              <p>Each and everyday peers in industries and professions the world over have moments where they need that elusive insight or piece of information that will help them tackle their jobs more effectively.</p>
              <div className="row why-list">
                <div className="col-lg-2">
                  <GatsbyImage
                    image={icon_1}
                    alt="icon1"
                    className="icon-img"
                  />
                </div>
                <div className="col-lg-10">
                  <p className="why-list-content">We say why not connect with another peer in your industry, function and job level and ask them to help?</p>
                </div>
              </div>
              <div className="row why-list mt-4">
                <div className="col-lg-2">
                  <GatsbyImage
                    image={icon_2}
                    alt="icon2"
                    className="icon-img"
                  />
                </div>
                <div className="col-lg-10">
                  <p className="why-list-content">In our club that is exactly what thousands of peers do every day. Literally at the click of a button.</p>
                </div>
              </div>
            </div>
          </Container>
        </section>

        <BackgroundImage
          Tag="section"
          className="sectors-bg"
          {...sectors_bg}
          preserveStackingContext
        >
          <section id="sectors">
            <Container>
              <div className="col-lg-12">
                <h2>SECTORS</h2>
              </div>
              <div className="col-lg-6">
                <div className="row why-list">
                  <div className="col-lg-2">
                    <GatsbyImage
                      image={icon_3}
                      alt="icon3"
                      className="icon-img"
                    />
                  </div>
                  <div className="col-lg-10">
                    <p className="why-list-content">There is little point in listing the range of sectors or countries in which the club operates. We have peers everywhere and they in turn invite their own networks to our problem solving meetings which means we are continually growing.</p>
                  </div>
                </div>
                <div className="row why-list mt-4">
                  <div className="col-lg-2">
                    <GatsbyImage
                      image={icon_4}
                      alt="icon4"
                      className="icon-img"
                    />
                  </div>
                  <div className="col-lg-10">
                    <p className="why-list-content">Rest assured, whatever your interest as a peer and whatever help you need, and wherever you are, The Peer Club will help.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <GatsbyImage
                  image={sectors_img}
                  alt="Video call"
                  className="sectors-img"
                />
              </div>
            </Container>
          </section>
        </BackgroundImage>

        <BackgroundImage
          Tag="section"
          className="co-peers-bg"
          {...co_peers_bg}
          preserveStackingContext
        >
          <section id="co-peers">
            <Container>
              <div className="col-lg-6">
                <h2 className="text-white"><span>YOUR</span> CO-PEERS <span>ARE WAITING TO</span> ENGAGE</h2>
                <Link to="#">
                  <button className="primary-btn">JOIN NOW</button>
                </Link>
              </div>
              <div className="col-lg-6">
                <GatsbyImage
                  image={co_peers_img}
                  alt="Working girls"
                  className="co-peers-img"
                />
              </div>
            </Container>
          </section>
        </BackgroundImage>

        <section id="how-we-work">
          <Container>
            <div className="col-lg-6">
              <GatsbyImage
                image={how_we_works_img}
                alt="How we works"
                className="how-we-works-img"
              />
            </div>
            <div className="col-lg-6">
              <h3>HOW</h3>
              <h2>WE WORK</h2>
              <p>Fill out our membership forms so we understand your areas of expertise and the talking points that you would like your fellow peers to help you with.</p>
            </div>
          </Container>
        </section>

        <BackgroundImage
          Tag="section"
          className="information-bg"
          {...information_bg}
          preserveStackingContext
        >
          <section id="information">
            <Container>
              <div className="col-lg-12">
                <h3>HOW WE USE THIS</h3>
                <h2>INFORMATION</h2>
                <p>We are a private club which and we do not make our membership lists available to third parties at any time.</p>
                <p>Nor do we a provide a look up function where people can research who are current members of the club.</p>
                <p>We only ever share contact information between co-peers when facilitating their meeting requests.</p>
              </div>
            </Container>
          </section>
        </BackgroundImage>

        <BackgroundImage
          Tag="section"
          className="investment-bg"
          {...investment_bg}
          preserveStackingContext
        >
          <section id="investment">
            <Container>
              <div className="col-lg-12 investment-container">
                <h3>YOUR TIME</h3>
                <h2>INVESTMENT</h2>
                <p>Information gathered here is used to profile match you with other peers with the same job level, industry or sector type and interests.</p>
                <p>This ensures you get to collaborate with co-peers around the world with matching experience and interests. And this guarantees your time investment in the club is well rewarded with the knowledge and insights you need whenever you need them.</p>
              </div>
            </Container>
          </section>
        </BackgroundImage>

        <section id="register">
          <Container>
            <div className="col-lg-6">
              <h3>REGISTER</h3>
              <h2>TO PEER CLUB</h2>
              <p>Already a member? <span>LOG IN</span></p>
              <form id="register-form" method="POST">
                  <input type="email" name="email"  placeholder="Email" required/>
                  <input type="password" name="password" placeholder="Password" equired/>
                  <input type="submit" value="REGISTER" class="register-form-btn"/>
              </form>
            </div>
            <div className="col-lg-6">
              <GatsbyImage
                image={register_img}
                alt="Register"
                className="register-img"
              />
            </div>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default HomeOldPage
